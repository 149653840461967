<template>
  <div>
    <v-card>
      <v-card-title>PAPELETAS DE DESCANSO</v-card-title>
    </v-card>
    <v-alert
      border="left"
      outlined
      text
      :type="alert.tipo"
      v-if="alert.mostrar"
    >
      <v-row>
        <v-col>
          <h4>{{ alert.mensaje }}</h4>
        </v-col>
        <v-col class="text-right">
          <v-btn
            :color="alert.color_boton"
            elevation="2"
            small
            dark
            @click="cerrar_alert"
            >Cerrar</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-if="alert.errores.length > 0">
        <v-col>
          <ul>
            <li v-for="e in alert.errores" :key="e">{{ e }}</li>
          </ul>
        </v-col>
      </v-row>
    </v-alert>
    <div class="card">
      <div class="card-header card-header-primary card-header-icon">
        <div :class="`card-icon ${color_icono}`">
          <i class="material-icons" v-show="icono">{{ icono }}</i>
        </div>
      </div>

      <div class="buscador">
        <div class="custom-size-container">
          <v-text-field
            label="Buscar..."
            @input="loadItems"
            dense
            class="custom-size-text-field"
          ></v-text-field>
        </div>
      </div>

      <div class="card-body">
        <spinner v-if="spinner"></spinner>

        <!-- <spinner v-if="spinner"></spinner> -->
        <v-simple-table :height="table_height" class="table-container">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(data, index) in data_header"
                  :key="index"
                  class="text-center"
                >
                  {{ data.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in data"
                :key="index"
                class="text-center"
              >
                <td>
                  {{ item.sistema_turnos_id }}
                </td>

                <td>
                  {{ item.wellboat }}
                </td>
                <td>
                  {{ item.full_name }}
                </td>
                <td>
                  {{ item.type }}
                </td>
                <td>
                  {{ item.init_date | format_fecha }}
                </td>
                <td>
                  {{ item.end_date | format_fecha }}
                </td>
                <td>
                  <a
                    @click="viewFile(item.original_file_url)"
                    target="_blank"
                    class=""
                  >
                    <v-icon large style="color:#ed4c4c;">
                      mdi-file-eye
                    </v-icon>
                  </a>
                </td>
                <td>
                  <div class="upload-button-container">
                    <label
                      :for="`fileInput_${item.id}`"
                      class="upload-button rounded my-auto mx-auto"
                    >
                      <v-icon large style="color:#32ac19">
                        mdi-file-upload
                      </v-icon>
                    </label>
                    <v-file-input
                      @change="guardarPapeleta(item)"
                      type="file"
                      :id="`fileInput_${item.id}`"
                      v-model="item.file"
                      accept=".pdf"
                      style="max-width: 130px; display: none;"
                    />
                  </div>
                </td>
                <!-- <td>
                                    <div class="upload-button-container">
                                        <label for="viewPapeleta" class="upload-button rounded my-auto mx-auto" :disabled="!item.path">
                                            <v-icon large style="color:#ed4c4c;" v-if="item.path">
                                                mdi-file-eye
                                            </v-icon>
                                            <v-icon large style="color:#7a7a7a;" v-else-if="item.path != null">
                                                mdi-file-eye
                                            </v-icon>
                                        </label>
                                        <v-btn @click="viewFile(item)" id="viewPapeleta" ref="viewPapeleta"
                                        :disabled="!item.path || item.path === null" style="display:none;">Ver archivo</v-btn>
                                    </div>
                                </td> -->
                <td>
                  <a
                    @click="viewFile(`${storage_url}papeletas/${item.file}`)"
                    target="_blank"
                    class=""
                    v-if="item.file"
                  >
                    <v-icon large style="color:#ed4c4c;">
                      mdi-file-eye
                    </v-icon>
                  </a>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// import axios from 'axios';
// import DatePicker from "vue2-datepicker";
// import DataTable from '@/components/base/DataTable.vue';

export default {
  data: () => ({
    data_header: [
      //Consultar tema de permisos o lo de visible para quienes
      // { text: 'id', value: 'id', visible: [6, 7, 8] }, //solo para testear
      { text: "Folio", value: "creado_por", visible: [6, 7, 8] },
      { text: "Wellboat", value: "wellboat", visible: [6, 7, 8] },
      { text: "Personal", value: "personal", visible: [6, 7, 8] },
      { text: "Tipo", value: "tipo", visible: [6, 7, 8] },
      { text: "Inicio", value: "inicio", visible: [6, 7, 8] },
      { text: "Termino", value: "termino", visible: [6, 7, 8] },
      { text: "Original", value: "original_file_url", visible: [6, 7, 8] },
      {
        text: "Adjuntar Archivo",
        value: "adjuntar_archivo",
        visible: [6, 7, 8],
      },
      { text: "Archivo Firmado", value: "visualizar", visible: [6, 7, 8] },
      // ver si se utilizará
      // { text: 'Archivo adjunto', value: 'archivo_adjunto', visible: [6, 7, 8] },
    ],
    sortBy: "", // Columna actual por la que se ordena
    sortDesc: false, // Dirección de la ordenación (ascendente o descendente)
    table_height: 500,
    data: [],
    files: [],
    spinner: false,
    titulo: "Papeletas de Descanso",
    color_icono: "azul",
    icono: "list",
    // file: null,
    page: 1,
    last_page: null,
    alert: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
  }),
  mounted() {
    // if (window.innerHeight < 768) {
    //     this.table_height = 300;
    // };
    //this.restoreAttachments();
    this.cargarData();
  },
  methods: {
    async loadItems($event) {
      console.log("event:", $event);
      let id = this.user.wellboat.id;
      this.data = [];

      const url = `${this.base_url}papeletas?search=${$event}&wellboats_id=${id}`;
      await this.axios.get(url, this.headers_json).then((r) => {
        this.data = r.data.data;
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    async cargarData() {
      let id = this.user.wellboat.id
      const url = `${this.base_url}papeletas?wellboats_id=${id}`;
      await this.axios.get(url, this.headers_json).then((r) => {
        this.data = r.data.data;
      });
    },
    async guardarPapeleta(item) {
      this.cerrar_alert();
      this.spinner = true;
      console.log("id: ", item.id);
      const index = this.data.findIndex((fila) => fila.id == item.id);
      try {
        const formData = new FormData();

        formData.append("file", item.file);
        formData.append("id", item.id);

        const url = `${this.base_url}upload_papeleta`;
        const response = await this.axios.post(url, formData, this.headers_file);

        this.alert.mostrar = true;
        this.alert.tipo = "success";
        this.alert.color_boton = "green";
        this.alert.mensaje = response.data.message;

        this.$set(this.data[index], "file", response.data.item.file);
      } catch (error) {
        this.alert.mostrar = true;
        this.alert.tipo = "error";
        this.alert.color_boton = "red";
        this.alert.mensaje =
          error.response?.data?.error || "Error al conectar con servidor";
        //this.alert.errores = error.response.data.errors;
        console.error("Error:", error.message || "Unknown error");
      }
      this.spinner = false;
    },

    viewFile(link) {
      //let link = `${this.storage_url}papeletas/${item.path}`;
      //let link = item.file?`${this.storage_url}papeletas/${item.path}`:item.original_file_url
      console.log(link);
      window.open(link);
    },
    cerrar_alert() {
      this.alert.mostrar = false;
      this.alert.tipo = "";
      this.alert.color_boton = "";
      this.alert.mensaje = "";
      this.alert.errores = [];
    },
    ...mapMutations(["actualizarBaseUrl", "mostrarLoading", "ocultarLoading"]),
  },

  computed: {
    ...mapState(["loading", "base_url", "headers", "user", "storage_url","headers_json","headers_file"]),
    inicio_formated() {
      return this.formatDate(this.inicio);
    },
    termino_formated() {
      return this.formatDate(this.termino);
    },
    emptyData: (data) => {
      return data.listBody.length > 0 ? false : true;
    },
  },
};
</script>
<style scoped>
th {
  background-color: #355f94 !important;
  color: #fff !important;
  border: 1px solid #fff;
}

/* Hace que el encabezado sea fijo */
.table-container {
  overflow-y: auto;
  /* Agrega una barra de desplazamiento vertical si es necesario */
}

.table-container thead {
  position: sticky;
  top: 0;
  z-index: 1;
  /* Asegura que el encabezado esté por encima del contenido desplazable */
}

.upload-button {
  background-color: #ffffff;
  color: #fff;
  border: none;
  padding: 3px 6px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #d8dee8;
}

.buscador {
  margin-right: 10%;
}
.custom-size-container {
  width: 470px;
  margin: 0px 0px 0px auto;
  margin-bottom: -19px;
}

.custom-size-text-field {
  height: 50px;
  font-size: 14px;
}
</style>
